import { useMutation, useQuery } from "@tanstack/react-query";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { toast } from "sonner";

import { isApiError } from "@/api";
import { authenticate, refreshToken } from "@/api/functions/auth";
import { useUser } from "@/api/queries/user";
import { getJWT, removeJWT, setJWT } from "@/utils/jwt";
import { removeQueryId, setQueryId } from "@/utils/queryId";

export function useIsAuthenticated() {
  const { data: user } = useUser();

  const isAuthenticated = user ? Number.isInteger(user.tnc_timestamp) : false;

  return isAuthenticated;
}

export function useRefreshTokenInBackground({ enabled }: { enabled: boolean }) {
  const { mutateAsync: refreshToken } = useRefreshToken();

  const refreshQuery = useQuery({
    queryKey: ["refreshToken"],
    enabled,
    refetchInterval: 1000 * 30,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    queryFn: () => refreshToken({ token: getJWT()! }),
  });

  return refreshQuery;
}

export function useRefreshToken() {
  const WebApp = useWebApp() as WebApp;

  const mutation = useMutation<{ jwt: string }, unknown, { token: string }>({
    mutationKey: ["refreshToken"],
    mutationFn: ({ token }) => refreshToken(token),
    onSuccess: (data) => setJWT(data.jwt),
    onError: (error) => {
      if (isApiError(error)) {
        if (error.statusCode === 401) {
          toast.error("Session expired", {
            description:
              "You have been logged out due to session expiration. Please, reopen the application.",
            duration: Number.POSITIVE_INFINITY,
            action: { label: "Close app", onClick: () => WebApp.close() },
          });

          removeJWT();
          removeQueryId();
        }
      }
    },
  });

  return mutation;
}

export function useSignIn() {
  const WebApp = useWebApp() as WebApp;

  const mutation = useMutation<{ jwt: string }, unknown, { initData: string }>({
    mutationKey: ["auth"],
    mutationFn: ({ initData }) => authenticate(initData),
    onSuccess: (data) => {
      setJWT(data.jwt);

      if (WebApp.initDataUnsafe.query_id) {
        setQueryId(WebApp.initDataUnsafe.query_id);
      }
    },
    onError: (error) => {
      if (isApiError(error) && error.statusCode === 401) {
        removeJWT();
        removeQueryId();

        toast.error("Session expired", {
          description:
            "You have been logged out due to session expiration. Please, reopen the application.",
          duration: Number.POSITIVE_INFINITY,
          action: { label: "Close app", onClick: () => WebApp.close() },
        });
      }
    },
  });

  return mutation;
}

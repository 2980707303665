import { FetchError, ofetch } from "ofetch";

import { config } from "@/config";
import { getJWT } from "@/utils/jwt";

export type ApiError = {
  code: number;
  details: string[];
  error: string;
  status: string;
};

export const isApiError = (error: unknown): error is FetchError<ApiError> =>
  error instanceof FetchError;

export const getTickerImage = (ticker: Ticker) =>
  `${import.meta.env.VITE_CDN_URL}/icons/currencies/${ticker}.svg`;

export const fetcher = ofetch.create({
  baseURL: config.apiUrl,
  timeout: 60000,
  retryStatusCodes: [],
  onRequest: async (ctx) => {
    try {
      const jwt = getJWT();

      if (jwt) {
        const headers = new Headers();

        headers.set("Authorization", `Bearer ${jwt}`);

        ctx.options.headers = headers;
      }
    } catch (error) {
      console.error("Request error:", { error });
    }
  },
});

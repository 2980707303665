import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { BackButton, MainButton } from "@vkruglikov/react-telegram-web-app";

import { useInfiniteTransactionsQuery } from "@/api/queries/transactions";
import { TransactionItem } from "@/components/home/transaction-item";
import { Heading } from "@/components/ui/heading";

export const Route = createFileRoute("/transactions/")({
  component: TransactionsPage,
});

function TransactionsPage() {
  const {
    pages,
    hasNextPage,
    isFetching,
    isLoading,
    isPending,
    fetchNextPage,
  } = useInfiniteTransactionsQuery();

  const navigate = useNavigate();

  return (
    <>
      <BackButton onClick={() => navigate({ to: "/" })} />
      <div className="px-4 py-6">
        <Heading className="mb-4">All Transactions</Heading>
        <div className="rounded-3xl bg-wrapper">
          {pages.map((page) =>
            page.items.map((item) => (
              <TransactionItem key={`tx-${item.hash}`} transaction={item} />
            )),
          )}
        </div>
      </div>
      {hasNextPage && (
        <MainButton
          text="Load more"
          onClick={() => fetchNextPage()}
          progress={isFetching || isLoading || isPending}
        />
      )}
    </>
  );
}

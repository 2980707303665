import { useWebApp } from "@vkruglikov/react-telegram-web-app";

import Secure from "@/assets/icons/secure.svg?react";
import TxBtc from "@/assets/icons/tx-btc.svg?react";
import Wallet from "@/assets/icons/wallet.svg?react";
import { Button } from "@/components/ui/button";
import { Terms } from "@/components/welcome/terms";

export function WelcomePage() {
  const WebApp = useWebApp() as WebApp;

  return (
    <div className="grid h-full items-center gap-8 p-6">
      <img
        src="/logo.svg"
        alt="Whitechain logo"
        className="mx-auto h-20 w-20 dark:hidden"
      />
      <img
        src="/logo-dark.svg"
        alt="Whitechain logo"
        className="mx-auto hidden h-20 w-20 dark:block"
      />
      <h1 className="mx-auto max-w-xs text-center text-[32px] font-medium leading-10 text-foreground">
        Your Wallet inside Telegram
      </h1>
      <div className="mx-auto grid max-w-xs gap-4">
        <div className="grid grid-cols-[min-content,1fr] grid-rows-[min-content,1fr] items-start gap-x-4 gap-y-2">
          <div className="row-span-2 flex size-12 items-center justify-center rounded-full bg-secondary dark:bg-wrapper">
            <TxBtc className="text-foreground" width={24} height={24} />
          </div>
          <h2 className="text-lg/5 font-medium text-foreground">
            Instant Transactions
          </h2>
          <p className="text-text">
            Send and receive crypto via Telegram in a single instant
          </p>
        </div>
        <div className="grid grid-cols-[min-content,1fr] grid-rows-[min-content,1fr] items-start gap-x-4 gap-y-2">
          <div className="row-span-2 flex size-12 items-center justify-center rounded-full bg-secondary dark:bg-wrapper">
            <Wallet className="text-foreground" width={24} height={24} />
          </div>
          <h2 className="text-lg/5 font-medium text-foreground">
            Easy Interaction
          </h2>
          <p className="text-text">
            Manage transfers, receive, and explore assets effortlessly within a
            wallet
          </p>
        </div>
        <div className="grid grid-cols-[min-content,1fr] grid-rows-[min-content,1fr] items-start gap-x-4 gap-y-2">
          <div className="row-span-2 flex size-12 items-center justify-center rounded-full bg-secondary dark:bg-wrapper">
            <Secure className="text-foreground" width={24} height={24} />
          </div>
          <h2 className="text-lg/5 font-medium text-foreground">
            Top-Notch Security
          </h2>
          <p className="text-text">
            You don’t have to worry about your asset's safety with us
          </p>
        </div>
      </div>
      <div className="mx-auto w-full max-w-[340px]">
        {WebApp.initData ? (
          <Terms />
        ) : (
          <Button asChild>
            <a
              href="https://t.me/whitechain_wallet_bot"
              rel="noreferrer noopenner"
            >
              Open @whitechain_wallet_bot
            </a>
          </Button>
        )}
      </div>
    </div>
  );
}

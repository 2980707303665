import { useMutation, useQuery } from "@tanstack/react-query";

import { acceptTerms, fetchUser } from "@/api/functions/user";
import { User } from "@/api/types/user";
import { getUserId } from "@/utils/userId";

export function useUser() {
  const userId = getUserId();

  const query = useQuery({
    queryFn: () => fetchUser(),
    refetchInterval: 1000 * 60,
    queryKey: ["user"],
    enabled: false,
  });

  return { userId, ...query };
}

export const useAcceptTerms = ({
  onSuccess,
}: {
  onSuccess: (data: User) => void;
}) => {
  const { mutateAsync, error, isPending } = useMutation({
    mutationKey: ["acceptTerms"],
    mutationFn: () => acceptTerms(),
    onSuccess,
  });

  return { acceptTerms: mutateAsync, error, isPending };
};

import { Slot } from "@radix-ui/react-slot";
import { cva,VariantProps } from "class-variance-authority";
import { ButtonHTMLAttributes, forwardRef } from "react";

import Loader from "@/assets/icons/loader.svg?react";
import { cn } from "@/utils/cn";

const variants = cva("flex items-center w-full transition-colors", {
  variants: {
    variant: {
      primary: [
        "bg-primary hover:bg-primary/90 active:bg-primary/80 disabled:bg-primary/25 text-white",
      ],
      secondary: [
        "bg-button-foreground hover:bg-button-foreground/90 active:bg-button-foreground/80 disabled:bg-button-foreground/25 text-white",
        "dark:bg-secondary dark:text-black dark:hover:bg-secondary/90 dark:active:bg-secondary/80 dark:disabled:bg-secondary/25",
      ],
    },
    size: {
      small: "rounded-md py-1.5 px-2 text-sm",
      medium: "rounded-lg px-4 py-3",
    },
    withIcon: {
      true: "justify-between",
      false: "justify-center",
    },
  },
  defaultVariants: {
    size: "medium",
    withIcon: false,
    variant: "primary",
  },
});

type ButtonProps = VariantProps<typeof variants> &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    asChild?: boolean;
    disabled?: boolean;
    loading?: boolean;
    icon?: React.ReactNode;
    iconPosition?: "left" | "right";
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { asChild = false, loading, size, variant, className, children, ...props },
    ref,
  ) => {
    const Component = asChild ? Slot : "button";

    return (
      <Component
        className={cn(variants({ size, variant }), className)}
        ref={ref}
        {...props}
      >
        {loading ? (
          <Loader
            width={24}
            height={24}
            className="mr-2 animate-spin text-white"
          />
        ) : (
          children
        )}
      </Component>
    );
  },
);

Button.displayName = "Button";

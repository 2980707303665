import { Link } from "@tanstack/react-router";
import { ComponentPropsWithoutRef, useMemo } from "react";

import { useAddressesQuery } from "@/api/queries/addresses";
import { useEquivalent } from "@/api/queries/balances";
import { Transaction } from "@/api/types";
import { cn } from "@/utils/cn";
import { formatTimestamp } from "@/utils/dates";
import { truncateString } from "@/utils/strings";
import { formatTransaction } from "@/utils/tx";

import { TickerImage } from "../ui/ticker-image";

type TransactionProps = ComponentPropsWithoutRef<"a"> & {
  transaction: Transaction;
};

export function TransactionItem({ className, transaction }: TransactionProps) {
  const priceEquivalents = useEquivalent();
  const { addresses } = useAddressesQuery();

  const equivalent = priceEquivalents.equivalent(
    transaction.unit,
    transaction.value,
  );

  const isUserTx = addresses?.includes(transaction.from);
  const displayAddress = isUserTx ? transaction.to : transaction.from;

  const tx = useMemo(
    () => formatTransaction(transaction, isUserTx),
    [transaction, isUserTx],
  );

  return (
    <Link
      to="/transactions/$id"
      params={{ id: transaction.id }}
      className={cn(
        "group relative flex items-center border-b border-wrapper px-4 py-3 last:border-none",
        className,
      )}
    >
      <div className="relative mr-3 shrink-0">
        <TickerImage ticker={transaction.unit} className="size-8" />
        <tx.Icon className="absolute left-5 top-5 text-section" />
      </div>
      <div className="mr-2 w-max">
        <p className="text-sm font-medium text-foreground">
          {tx.text} {truncateString(displayAddress)}
        </p>
        <div className="text-sm text-text">
          {formatTimestamp(transaction.timestamp ?? transaction.created_at)}
        </div>
      </div>
      <div className="grow justify-self-end text-right">
        <p className={cn("text-sm font-medium", tx.class)}>{tx.value}</p>
        <p className="text-xs text-text">&asymp; ${equivalent}</p>
      </div>
    </Link>
  );
}

import "@/assets/styles/app.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { WebAppProvider } from "@vkruglikov/react-telegram-web-app";
import ReactDOM from "react-dom/client";
import { toast } from "sonner";

import { isApiError } from "@/api";
import { DefaultError } from "@/components/default-error";
import { Toaster } from "@/components/ui/sonner";
import { routeTree } from "@/routeTree.gen";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      retry: false,
    },
    mutations: {
      onError: (error) => {
        if (isApiError(error)) {
          if (error.data?.details) {
            error.data?.details.map((detail) =>
              toast.error("Error", { description: detail, duration: 2000 }),
            );
          } else {
            toast.error("Error", {
              description: error.data?.error,
              duration: 2000,
            });
          }
        } else {
          toast.error("Error", { description: error.message, duration: 2000 });
        }
      },
    },
  },
});

const router = createRouter({
  routeTree,
  context: { queryClient },
  defaultPreload: "intent",
  defaultPreloadStaleTime: Infinity,
  defaultErrorComponent: DefaultError,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

function Setup() {
  const webApp = window.Telegram.WebApp;

  webApp.SettingsButton.onClick(() => {
    router.navigate({ to: "/settings" });
  });

  webApp.SettingsButton.show();
}

Setup();

root.render(
  <QueryClientProvider client={queryClient}>
    <WebAppProvider>
      <RouterProvider router={router} />
      <Toaster richColors />
    </WebAppProvider>
    {import.meta.env.DEV && <ReactQueryDevtools />}
  </QueryClientProvider>,
);

import { useQuery } from "@tanstack/react-query";

import { fetchAddresses } from "@/api/functions/addresses";

export function useAddressesQuery() {
  const { data: addresses } = useQuery({
    queryKey: ["addresses"],
    queryFn: () => fetchAddresses(),
    select: (data) => data?.addresses,
  });

  const primary = addresses?.[0] ?? "";
  const isPrimaryExists = !!addresses?.[0];

  const hasAddress = (address: string) => {
    return addresses?.includes(address) ?? false;
  };

  return { addresses, primary, isPrimaryExists, hasAddress };
}

export interface Config {
  precision: number;
  currency: Ticker;
  termsLink: string;
  apiUrl: string;
  policyLink: string;
  explorerLink: string;
  cdnUrl: string;
}

export const config: Config = {
  precision: 18,
  currency: "WBT",
  cdnUrl: "https://cdn.whitechain.io",
  explorerLink: import.meta.env.VITE_EXPLORER_URL,
  apiUrl: import.meta.env.VITE_API_V1_URL,
  termsLink:
    "https://cdn.whitechain.io/wallet/Whitechain-Wallet-Terms-and-Conditions.pdf",
  policyLink:
    "https://cdn.whitechain.io/wallet/Whitechain-Wallet-Privacy-Policy.pdf",
};

export const getTickerIconURI = (ticker: Uppercase<string>) =>
  `${config.cdnUrl}/icons/currencies/${ticker}.svg`;

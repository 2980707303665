import { Transaction } from "@/api/types";
import FailedIcon from "@/assets/icons/failed.svg?react";
import PendingIcon from "@/assets/icons/pending.svg?react";
import SuccessIcon from "@/assets/icons/success.svg?react";
import UnconfirmedIcon from "@/assets/icons/unconfirmed.svg?react";
import { formatNumber } from "@/utils/numbers";

export const icons = {
  failed: FailedIcon,
  success: SuccessIcon,
  pending: PendingIcon,
  unconfirmed: UnconfirmedIcon,
};

function transactionIcon(tx: Transaction) {
  switch (tx.state) {
    case "completed":
    case "incoming":
      return icons.success;
    case "declined_by_user":
    case "expired":
      return icons.unconfirmed;
    case "failed":
      return icons.failed;
    case "created":
    case "confirmed_by_user":
    case "confirmation_requested":
      return icons.pending;

    default:
      return icons.pending;
  }
}

export function formatTransaction(
  tx?: Transaction,
  isUserTx?: boolean,
  rawValue = false,
) {
  if (!tx)
    return {
      text: "Unknown",
      value: "0",
      Icon: transactionIcon({} as Transaction),
      class: "text-text",
      isUserTx,
    };

  const value = rawValue ? tx.value : `${formatNumber(tx.value, 8)} ${tx.unit}`;

  const defaultFormat = {
    text: "",
    value,
    Icon: transactionIcon(tx),
    rawValue: tx.value,
    class: "text-text",
    isUserTx,
  };

  switch (tx.state) {
    case "completed":
      return {
        text: isUserTx ? "Sent" : "Received",
        value: rawValue ? value : `${isUserTx ? "-" : "+"}${value}`,
        rawValue: tx.value,
        Icon: transactionIcon(tx),
        class: isUserTx ? "text-danger" : "text-success",
        isUserTx,
      };
    case "incoming":
      return {
        text: "Received",
        value: rawValue ? value : `+${value}`,
        rawValue: tx.value,
        Icon: transactionIcon(tx),
        class: "text-success",
        isUserTx,
      };
    case "failed":
      return {
        text: "Failed",
        value,
        rawValue: tx.value,
        Icon: transactionIcon(tx),
        class: "text-danger",
        isUserTx,
      };
    case "expired":
      return {
        text: "Expired",
        value,
        rawValue: tx.value,
        Icon: transactionIcon(tx),
        class: "text-foreground",
        isUserTx,
      };

    case "confirmed_by_user":
      defaultFormat.text = "Processing";
      return defaultFormat;
    case "confirmation_requested":
    case "created":
      defaultFormat.text = "Not confirmed";
      return defaultFormat;
    case "declined_by_user":
      defaultFormat.text = "Declined";
      return defaultFormat;
    default:
      defaultFormat.text = "Transaction";
      return defaultFormat;
  }
}

import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import { getNFTBalances } from "@/api/functions/balances";
import {
  estimateNFTTransaction,
  fetchNFT,
  fetchNFTs,
  transferNFT,
} from "@/api/functions/nft";
import { useAddressesQuery } from "@/api/queries/addresses";
import { TransferNFTPayload } from "@/api/types";

export function useNFT({
  contract,
  tokenId,
  owner,
}: {
  contract: string;
  tokenId: string;
  owner: string;
}) {
  const query = useQuery({
    queryKey: ["nft-list", contract, tokenId, owner],
    queryFn: () => fetchNFT(contract, tokenId, { query: { owner } }),
  });

  return query;
}

export function useNFTListQuery() {
  const { primary } = useAddressesQuery();

  const query = useQuery({
    enabled: !!primary,
    queryKey: ["nft-list", primary],
    queryFn: () => fetchNFTs(primary),
  });

  return query;
}

export function useInfiniteNFTQuery() {
  const { primary } = useAddressesQuery();

  const query = useInfiniteQuery({
    queryKey: ["infinite-nft", primary],
    initialPageParam: "",
    queryFn: ({ pageParam, signal }) =>
      fetchNFTs(primary, {
        query: { page_token: pageParam },
        signal,
      }),
    getNextPageParam: (lastPage) => lastPage.next_page_token,
  });

  const pages = query.data?.pages ?? [];

  return {
    ...query,
    pages,
  };
}

export function useNFTTransfer() {
  const { primary } = useAddressesQuery();

  const mutation = useMutation({
    mutationKey: ["token-transfer"],
    mutationFn: (body: Omit<TransferNFTPayload, "from" | "method">) =>
      transferNFT({
        ...body,
        from: primary!,
        method: "transfer",
      }),
  });

  return mutation;
}

export function useNFTBalancesQuery({ enabled }: { enabled: boolean }) {
  const { primary, isPrimaryExists } = useAddressesQuery();

  const isEnabled = isPrimaryExists && enabled;

  const query = useQuery({
    enabled: isEnabled,
    queryKey: ["nft-balances", primary],
    queryFn: () => getNFTBalances(primary),
  });

  return query;
}

export function useEstimateNFTTransaction() {
  const mutation = useMutation({
    mutationKey: ["estimateNFTTransaction"],
    mutationFn: (params: TransferNFTPayload) => estimateNFTTransaction(params),
  });

  return mutation;
}

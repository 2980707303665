import fecha from "fecha";

export function formatTimestamp(value: number, mask = "DD MMM YYYY, H:mm") {
  // Handle be timestamp
  if (value % 1000 !== 0) value *= 1000;

  const date = new Date(value);

  return fecha.format(date, mask);
}

import type { FetchOptions } from "ofetch";

import { fetcher } from "@/api";
import { NFTListItem } from "@/api/types";
import { getUserId } from "@/utils/userId";

import { Balances } from "../types/balances";

export function getNFTBalances(
  address: string,
  options?: FetchOptions<"json">,
) {
  return fetcher<ListResponse<NFTListItem>>(
    `/users/${getUserId()}/wallets/${address}/nft`,
    options,
  );
}

export function getBalanceByAddress(
  address: string,
  options?: FetchOptions<"json">,
) {
  return fetcher<Balances>(
    `/users/${getUserId()}/wallets/${address}/balances`,
    options,
  );
}

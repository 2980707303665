import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { BackButton, MainButton } from "@vkruglikov/react-telegram-web-app";
import { Loader } from "lucide-react";

import { useInfiniteNFTQuery } from "@/api/queries/nft";
import { NftItem } from "@/components/home/nft-item";
import { Heading } from "@/components/ui/heading";

export const Route = createFileRoute("/nft/")({
  component: NFTPage,
});

function NFTPage() {
  const navigate = useNavigate();
  const {
    pages: data,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteNFTQuery();

  return (
    <>
      <BackButton onClick={() => navigate({ to: "/" })} />
      <div className="px-4 py-6">
        <Heading className="mb-4">All Collections</Heading>
        <div className="rounded-3xl bg-wrapper p-4">
          {isLoading ? (
            <div className="py-12">
              <Loader className="mx-auto animate-spin text-primary" size="24" />
            </div>
          ) : (
            <div className="flex flex-wrap justify-start gap-2">
              {data.flatMap((page) =>
                page.items.map((item) => (
                  <NftItem
                    key={`${item.contract}/${item.tokenId}`}
                    nft={item}
                    backUrl="/nft"
                    className="mb-2 w-[30%]"
                  />
                )),
              )}
            </div>
          )}
        </div>
      </div>
      {hasNextPage && (
        <MainButton
          text="Load more"
          progress={isLoading}
          onClick={() => fetchNextPage()}
        />
      )}
    </>
  );
}

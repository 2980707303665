import { QueryClient } from "@tanstack/react-query";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { useThemeParams, useWebApp } from "@vkruglikov/react-telegram-web-app";
import { useEffect } from "react";
import { useLocalStorage } from "react-use";

import { useUser } from "@/api/queries/user";
import { WelcomePage } from "@/components/welcome/page";
import {
  useIsAuthenticated,
  useRefreshToken,
  useRefreshTokenInBackground,
  useSignIn,
} from "@/hooks/use-auth";
import { getJWT } from "@/utils/jwt";
import { getQueryId } from "@/utils/queryId";

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: RootComponent,
});

function RootComponent() {
  const WebApp = useWebApp() as WebApp;
  const [colorScheme] = useThemeParams();
  const [localTheme] = useLocalStorage("theme", "system", {
    raw: true,
  });

  const {
    data: user,
    refetch: fetchUser,
    isFetched: isUserFetched,
  } = useUser();

  const isAuthenticated = useIsAuthenticated();

  const { mutateAsync: signIn } = useSignIn();
  const { mutateAsync: refreshToken } = useRefreshToken();

  useRefreshTokenInBackground({ enabled: isAuthenticated });

  useEffect(() => {
    const html = document.querySelector("html");

    if (localTheme !== "system") {
      html!.classList.remove("light", "dark");
      html!.classList.add(localTheme as string);

      WebApp.setBackgroundColor(localTheme === "dark" ? "#080a28" : "#68ecbc");
      WebApp.setHeaderColor(localTheme === "dark" ? "#080a28" : "#68ecbc");
      return;
    }

    html!.classList.remove("light", "dark");
    html!.classList.add(colorScheme as string);

    WebApp.setBackgroundColor(colorScheme === "dark" ? "#080a28" : "#68ecbc");
    WebApp.setHeaderColor(colorScheme === "dark" ? "#080a28" : "#68ecbc");
  }, [colorScheme, localTheme]);

  useEffect(() => {
    async function initAuth() {
      const jwt = getJWT();
      const queryId = getQueryId();

      if (jwt && queryId === WebApp.initDataUnsafe.query_id) {
        await refreshToken({ token: jwt });
      } else {
        await signIn({ initData: WebApp.initData });
      }

      fetchUser();
    }

    initAuth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isUserFetched) {
    return (
      <div className="relative bg-wrapper">
        <div className="flex min-h-svh flex-col items-center justify-center">
          <div className="flex animate-pulse items-center gap-2">
            <div className="text-black dark:text-white">Authenticating</div>
            <div className="animate-spin">⏳</div>
          </div>
        </div>
      </div>
    );
  }

  if (!user?.tnc_timestamp) {
    return <WelcomePage />;
  }

  return (
    <>
      <div className="relative bg-gradient-to-b from-secondary via-50% to-primary to-[200%] dark:from-background dark:to-background">
        <main className="relative z-[1] flex min-h-svh flex-col bg-[url('/background-light.svg')] bg-top bg-repeat dark:bg-[url('/background-dark.svg')]">
          <Outlet />
        </main>
      </div>
      {import.meta.env.DEV && <TanStackRouterDevtools />}
    </>
  );
}

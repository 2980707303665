import {
  createFileRoute,
  useLoaderData,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { BackButton, useWebApp } from "@vkruglikov/react-telegram-web-app";
import { ArrowUpRight, CopyIcon, Loader } from "lucide-react";
import { Img } from "react-image";
import z from "zod";

import { fetchNFT } from "@/api/functions/nft";
import TokenIcon from "@/assets/icons/token.svg?react";
import { Button } from "@/components/ui/button";
import { config } from "@/config";
import { useCopyToClipboard } from "@/utils/copy";

const nftParamsSchema = z.object({
  contract: z.string(),
  tokenId: z.string(),
});

const nftSearchSchema = z.object({
  backUrl: z.string().optional(),
});

export const Route = createFileRoute("/nft/$contract/$tokenId/")({
  component: SingleNFTPage,
  parseParams: nftParamsSchema.parse,
  validateSearch: nftSearchSchema,
  loader: ({ params: { contract, tokenId }, context: { queryClient } }) => {
    return queryClient.ensureQueryData({
      queryKey: ["nft-list", contract, tokenId],
      queryFn: () => fetchNFT(contract, tokenId),
    });
  },
});

function SingleNFTPage() {
  const WebApp = useWebApp() as WebApp;
  const data = useLoaderData({ from: "/nft/$contract/$tokenId/" });
  const { backUrl } = useSearch({ from: "/nft/$contract/$tokenId/" });

  const navigate = useNavigate();
  const copy = useCopyToClipboard();

  return (
    <>
      <BackButton onClick={() => navigate({ to: backUrl ?? "/" })} />
      <div className="p-4">
        <div className="mx-auto max-w-[340px] rounded-4xl bg-wrapper p-3 pb-6">
          <div className="mb-4 flex max-h-96 w-full items-center justify-center overflow-hidden rounded-3xl bg-section">
            <Img
              src={data.imageUrl!}
              className="aspect-square size-full object-cover"
              unloader={
                <Img
                  src="/empty-nft.svg"
                  alt={data.tokenId}
                  className="aspect-square size-full object-cover"
                />
              }
              loader={
                <Loader
                  className="animate-spin text-primary"
                  width={24}
                  height={24}
                />
              }
            />
          </div>
          <div className="px-4">
            <div className="mb-3 space-y-1">
              <h1 className="break-all text-xl font-medium text-foreground">
                {data?.metadata?.name || data.contract} | #{data.tokenId}
              </h1>
              <div className="flex items-center gap-1">
                <TokenIcon />
                <span className="text-xs uppercase text-text">
                  {data?.type}
                </span>
              </div>
            </div>
            <div className="mb-4 flex items-center justify-between gap-4">
              <Button
                className="grow"
                variant="secondary"
                onClick={() =>
                  navigate({
                    to: "/withdraw/nft/$contract/$tokenId",
                    params: {
                      contract: data.contract,
                      tokenId: data.tokenId,
                    },
                  })
                }
              >
                <span>Send to address</span>
                <ArrowUpRight className="ml-auto text-text dark:text-black" />
              </Button>
            </div>
            <div className="space-y-3">
              <div>
                <div className="text-sm text-text">Collection</div>
                <div className="break-all text-xs font-medium leading-5 tracking-wide text-foreground">
                  {data.name || data.symbol || data.contract}
                </div>
              </div>
              <div className="mb-3">
                <p className="text-sm text-text">Token ID</p>
                <p className="break-all text-xs font-medium text-foreground">
                  {data.tokenId}
                </p>
              </div>
              <div>
                <div className="text-sm text-text">Contract Address</div>
                <div className="flex items-center justify-between gap-4 text-xs leading-5">
                  <button
                    type="button"
                    className="link text-left transition-colors"
                    onClick={() =>
                      WebApp.openLink(
                        `${config.explorerLink}/address/${data.contract}`,
                      )
                    }
                  >
                    <span className="break-all font-medium tracking-wide">
                      {data.contract}
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      copy(
                        data.contract,
                        "Contract address copied to clipboard",
                      )
                    }
                  >
                    <CopyIcon
                      size="16"
                      className="shrink-0 text-primary transition-colors hover:text-primary/90"
                    />
                  </button>
                </div>
              </div>
              <div>
                <div className="text-sm text-text">Creator</div>
                <div className="flex items-center justify-between gap-4 text-xs leading-5">
                  <button
                    type="button"
                    className="link text-left transition-colors"
                    onClick={() =>
                      WebApp.openLink(
                        `${config.explorerLink}/address/${data.creator}`,
                      )
                    }
                  >
                    <span className="break-all font-medium tracking-wide">
                      {data.creator}
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      copy(data.creator, "Creator address copied to clipboard")
                    }
                  >
                    <CopyIcon
                      size="16"
                      className="shrink-0 text-primary transition-colors hover:text-primary/90"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import {
  createFileRoute,
  useNavigate,
  useParams,
  useSearch,
} from "@tanstack/react-router";
import { BackButton, useWebApp } from "@vkruglikov/react-telegram-web-app";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { isAddress } from "viem";
import { z } from "zod";

import { useAddressesQuery } from "@/api/queries/addresses";
import { useEquivalent } from "@/api/queries/balances";
import { useCurrencies } from "@/api/queries/currencies";
import {
  useCreateTransaction,
  useEstimateTransaction,
} from "@/api/queries/transactions";
import ArrowRight from "@/assets/icons/arrow-right.svg?react";
import { Button } from "@/components/ui/button";
import { TickerImage } from "@/components/ui/ticker-image";
import { config } from "@/config";
import { formatNumber } from "@/utils/numbers";
import { truncateString } from "@/utils/strings";

const searchSchema = z.object({
  address: z
    .string()
    .min(1, "Address is required")
    .refine(isAddress, "Address is invalid"),
  amount: z.string().min(1, "Amount is required"),
});

const paramsSchema = z.object({
  currency: z.string(),
});

export const Route = createFileRoute(
  "/withdraw/currency/$currency/_layout/submit",
)({
  component: WithdrawCurrencySubmitPage,
  validateSearch: searchSchema,
  parseParams: paramsSchema.parse,
});

function WithdrawCurrencySubmitPage() {
  const WebApp = useWebApp() as WebApp;

  const params = useParams({
    from: "/withdraw/currency/$currency/_layout/submit",
  });

  const { address, amount } = useSearch({
    from: "/withdraw/currency/$currency/_layout/submit",
  });

  const navigate = useNavigate();

  const { equivalent } = useEquivalent();
  const { primary } = useAddressesQuery();
  const { currencies } = useCurrencies();
  const { mutateAsync } = useEstimateTransaction();

  const { mutateAsync: createTransaction, isPending } = useCreateTransaction({
    from: primary,
    to: address,
  });

  const [fee, setFee] = useState("");

  async function handleSubmit() {
    await createTransaction(
      {
        tx_currency: currencies[params.currency as Ticker].id,
        from: primary,
        to: address,
        value: amount.toString(),
      },
      { onSuccess: () => WebApp.close() },
    );
  }

  useEffect(() => {
    async function estimate() {
      const data = await mutateAsync({
        from: primary,
        tx_currency: currencies[params.currency as Ticker].id,
        to: address,
        value: amount.toString(),
      });

      setFee(data.fee);
    }

    if (!currencies || !primary) return;

    estimate();
  }, [currencies, primary]);

  return (
    <>
      <BackButton
        onClick={() =>
          navigate({
            to: "/withdraw/currency/$currency",
            search: { address, amount },
            params,
          })
        }
      />
      <div>
        <h1 className="mb-4 text-center text-lg font-medium text-foreground">
          Transaction Preview
        </h1>
        <div className="mb-3 flex flex-col items-center justify-center rounded-2xl bg-section p-4">
          <TickerImage
            ticker={params.currency as Ticker}
            className="mx-0 mb-2"
          />
          <div className="break-all text-center text-3xl font-medium text-foreground">
            {formatNumber(amount, 8)} {params.currency}
          </div>
          <p className="break-all text-center text-text">
            &asymp; ${equivalent(params.currency as Ticker, amount)}
          </p>
        </div>

        <div className="mb-4 text-left">
          <p className="text-sm text-text">Transaction fees</p>
          <p className="text-xs font-medium">
            {fee ? (
              <span className="text-danger">
                -{formatNumber(fee, 8)} {config.currency} (-$
                {equivalent(config.currency, fee)})
              </span>
            ) : (
              <Loader2 className="animate-spin" size={12} />
            )}
          </p>
        </div>
        <div className="mb-4 grid grid-cols-[1fr,24px,1fr] items-center gap-1">
          <div>
            <p className="text-sm text-text">Sender Address</p>
            <p className="text-xs font-medium text-foreground">
              {truncateString(primary!)}
            </p>
          </div>
          <div>
            <ArrowRight className="text-primary dark:text-secondary" />
          </div>
          <div className="text-right">
            <p className="text-sm text-text">Recipient Address</p>
            <p className="text-xs font-medium text-foreground">
              {truncateString(address)}
            </p>
          </div>
        </div>
        <Button
          className="mb-4 w-full"
          disabled={isPending}
          loading={isPending}
          onClick={handleSubmit}
        >
          Continue
        </Button>
        <p className="text-center text-sm text-warning">
          Ensure the transaction details are correct. Indicating incorrect
          recipient data can lead to the irretrievable loss of your assets
        </p>
      </div>
    </>
  );
}

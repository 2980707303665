import { Link } from "@tanstack/react-router";
import { Copy, Settings } from "lucide-react";
import { HTMLAttributes } from "react";

import { useAddressesQuery } from "@/api/queries/addresses";
import { useUser } from "@/api/queries/user";
import { cn } from "@/utils/cn";
import { useCopyToClipboard } from "@/utils/copy";
import { truncateString } from "@/utils/strings";

type HomeHeaderProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
};

export function HomeHeader({ className, ...props }: HomeHeaderProps) {
  const { data: user } = useUser();
  const { primary } = useAddressesQuery();
  const copy = useCopyToClipboard();

  const username = user?.user_name || user?.first_name || "Anonymous";

  return (
    <div className={cn("flex items-center gap-2", className)} {...props}>
      <button
        type="button"
        className="group relative z-10 flex grow items-center justify-center gap-3 rounded-full bg-wrapper px-4 py-2 text-left shadow-[0_4px_12px_0px_rgba(0,0,0,0.08)] transition-all hover:opacity-90"
        onClick={() => copy(primary, `Address copied to clipboard`)}
      >
        <div className="flex size-8 shrink-0 items-center justify-center rounded-full bg-white">
          <p className="font-medium text-black">{username[0]}</p>
        </div>
        <div className="flex w-full grow items-center justify-between">
          <div className="text-sm">
            <p className="text-text">Welcome, {username}</p>
            {primary && (
              <strong className="font-medium text-foreground">
                Address: {truncateString(primary)}
              </strong>
            )}
          </div>
          <div className="ml-auto">
            <Copy size={24} className="text-primary dark:text-secondary" />
          </div>
        </div>
      </button>
      <Link
        to="/settings"
        className="flex size-14 shrink-0 items-center justify-center rounded-full bg-wrapper shadow-[0_4px_12px_0px_rgba(0,0,0,0.08)] transition-all hover:opacity-90"
      >
        <Settings size={24} className="text-primary dark:text-secondary" />
      </Link>
    </div>
  );
}

import { createFileRoute, Link } from "@tanstack/react-router";
import { Loader2 } from "lucide-react";
import { useRef } from "react";
import { useWindowScroll } from "react-use";

import { useBalances, useEquivalent } from "@/api/queries/balances";
import ArrowDownLeft from "@/assets/icons/arrow-down-left-underline.svg?react";
import ArrowUpRight from "@/assets/icons/arrow-up-right-underline.svg?react";
import HomeCollections from "@/components/home/collections";
import { HomeCurrencies } from "@/components/home/currencies";
import HomeGames from "@/components/home/games";
import { HomeHeader } from "@/components/home/header";
import HomeTransactions from "@/components/home/transactions";
import { Button } from "@/components/ui/button";
import { config } from "@/config";
import { cn } from "@/utils/cn";

export const Route = createFileRoute("/")({
  component: Index,
});

function Index() {
  const { balances, isFetching } = useBalances();
  const { balance } = useEquivalent();
  const { y: windowY } = useWindowScroll();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const isWrapperFlatted = wrapperRef.current
    ? windowY >= wrapperRef.current.offsetTop
    : false;

  return (
    <>
      <div className="mb-6 space-y-4 px-2 pt-4">
        <HomeHeader className="mx-2" />
        <div className="mx-2">
          <p className="mb-1 text-sm">Your balance</p>
          {isFetching ? (
            <div className="flex h-14 items-center">
              <Loader2
                className="animate-spin text-primary dark:text-secondary"
                size={24}
              />
            </div>
          ) : (
            <>
              <div className="break-all text-[32px] font-medium leading-9 text-foreground">
                {balances.WBT} {config.currency}
              </div>
              <p className="break-all text-sm text-text">&asymp; ${balance}</p>
            </>
          )}
        </div>
        <div className="mx-2 grid grid-cols-2 gap-4">
          <Button asChild variant="secondary">
            <Link to="/deposit">
              <span>Receive</span>
              <ArrowDownLeft className="ml-auto text-white/50 dark:text-black/50" />
            </Link>
          </Button>
          <Button asChild variant="secondary">
            <Link to="/withdraw/currency">
              <span>Send</span>
              <ArrowUpRight className="ml-auto text-white/50 dark:text-black/50" />
            </Link>
          </Button>
        </div>
      </div>
      <div
        ref={wrapperRef}
        className={cn(
          "flex grow flex-col rounded-t-3xl bg-wrapper px-4 py-6 shadow-[0px_-4px_14px_0px_rgba(0,0,0,0.1)] transition-[border-radius] dark:shadow-[0px_-4px_14px_0px_rgba(0,0,0,0.25)]",
          { "rounded-t-none": isWrapperFlatted },
        )}
      >
        <HomeGames className="mb-6" />
        <HomeCurrencies className="mb-6" />
        <HomeTransactions className="mb-6" />
        <HomeCollections />
      </div>
    </>
  );
}

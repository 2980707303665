import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { CopyIcon } from "lucide-react";

import { useAddressesQuery } from "@/api/queries/addresses";
import { Badge } from "@/components/ui/badge";
import { Heading } from "@/components/ui/heading";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { TickerImage } from "@/components/ui/ticker-image";
import { useCopyToClipboard } from "@/utils/copy";

export const Route = createFileRoute("/settings/addresses/")({
  component: AddressesPage,
});

function AddressesPage() {
  const navigate = useNavigate();
  const copy = useCopyToClipboard();

  const { addresses, primary } = useAddressesQuery();

  return (
    <>
      <BackButton onClick={() => navigate({ to: "/settings" })} />
      <div className="px-4 py-6">
        <Heading className="mb-4">My addresses</Heading>
        <div className="space-y-4">
          <RadioGroup value={primary} name="address">
            {addresses?.map((address) => (
              <div key={address} className="rounded-3xl bg-wrapper p-4">
                <div className="mb-3 grid grid-cols-[40px,1fr,1fr,24px] items-center gap-4">
                  <TickerImage ticker="WBT" />
                  <div className="text-sm">
                    <div className="flex items-center gap-1 font-medium text-foreground">
                      <span>WBT</span>
                      {address === primary && (
                        <Badge variant="success">Primary</Badge>
                      )}
                    </div>
                    <div>Currency</div>
                  </div>
                  <div className="text-sm">
                    <div className="font-medium text-foreground">
                      Whitechain
                    </div>
                    <div>Network</div>
                  </div>
                  <RadioGroupItem
                    disabled={address !== primary}
                    value={address}
                  />
                </div>
                <div className="flex items-center justify-between gap-4 rounded-xl bg-section p-3">
                  <span className="break-all text-text">{address}</span>
                  <button
                    type="button"
                    onClick={() => copy(address, "Address copied to clipboard")}
                  >
                    <CopyIcon
                      size="24"
                      className="shrink-0 text-primary transition-colors hover:text-primary/90"
                    />
                  </button>
                </div>
              </div>
            ))}
          </RadioGroup>
        </div>
      </div>
    </>
  );
}

import { createFileRoute } from "@tanstack/react-router";

import { Button } from "@/components/ui/button";

export const Route = createFileRoute("/maintenance")({
  component: MaintenancePage,
});

function MaintenancePage() {
  return (
    <div className="flex h-svh flex-col items-center justify-center bg-section">
      <div className="mx-auto max-w-xs text-center">
        <div className="mb-6 text-6xl">🛠</div>
        <h1 className="mb-2 text-lg font-medium text-foreground">
          Scheduled Maintenance
        </h1>
        <p className="mb-8 text-text">
          We apologize for any inconvenience this may cause and appreciate your
          understanding and patience.
        </p>
        <Button onClick={() => window.location.reload()}>Refresh</Button>
      </div>
    </div>
  );
}

import {
  createFileRoute,
  Link,
  useLoaderData,
  useNavigate,
} from "@tanstack/react-router";
import { BackButton, useWebApp } from "@vkruglikov/react-telegram-web-app";
import { CopyIcon, RepeatIcon } from "lucide-react";
import { useMemo } from "react";
import { Address } from "viem";

import { fetchTransaction } from "@/api/functions/transactions";
import { useAddressesQuery } from "@/api/queries/addresses";
import { useEquivalent } from "@/api/queries/balances";
import { Button } from "@/components/ui/button";
import { TickerImage } from "@/components/ui/ticker-image";
import { config } from "@/config";
import { cn } from "@/utils/cn";
import { useCopyToClipboard } from "@/utils/copy";
import { formatTimestamp } from "@/utils/dates";
import { formatTransaction } from "@/utils/tx";

export const Route = createFileRoute("/transactions/$id")({
  loader: ({ params }) => fetchTransaction(params.id),
  component: TransactionPage,
});

function TransactionPage() {
  const data = useLoaderData({ from: "/transactions/$id" });
  const WebApp = useWebApp() as WebApp;
  const navigate = useNavigate();

  const copy = useCopyToClipboard();
  const { hasAddress } = useAddressesQuery();
  const priceEquivalent = useEquivalent();

  const isFromUser = hasAddress(data.from);

  const tx = useMemo(
    () => formatTransaction(data, isFromUser),
    [data, isFromUser],
  );

  const equivalent = useMemo(
    () => priceEquivalent.equivalent(data.unit, data.value || "0"),
    [data, priceEquivalent],
  );

  const txStateTitle = useMemo(() => {
    if (!data) return "Unknown type";
    if (data.state === "completed" || data.state === "incoming")
      return "Completed";

    return tx.text;
  }, [data, tx.text]);

  function handleLinkClick(type: "hash" | "address", value: string) {
    if (type === "hash")
      WebApp.openLink(`${import.meta.env.VITE_EXPLORER_URL}/tx/${value}`);
    else
      WebApp.openLink(`${import.meta.env.VITE_EXPLORER_URL}/address/${value}`);
  }

  return (
    <div className="p-4">
      <BackButton onClick={() => navigate({ to: "/" })} />
      <div className="mx-auto max-w-[340px] rounded-4xl bg-wrapper p-3">
        <div className="mb-4 flex flex-col items-center justify-center rounded-[28px] bg-section p-4">
          <div className="relative mb-3 size-12">
            <TickerImage ticker={data.unit} className="size-12" />
            <tx.Icon className="absolute left-[30px] top-[30px] text-section" />
          </div>
          <p className="text-sm text-text">{txStateTitle}</p>
          <strong
            className={cn(
              "mb-1 break-all text-center text-3xl font-medium",
              tx.class,
            )}
          >
            {tx.value}
          </strong>
          <p className="break-all text-center text-sm text-text">
            &asymp; ${equivalent}
          </p>
        </div>
        <div className="p-3">
          {data && (
            <>
              <div
                className={cn("space-y-3", data?.value && isFromUser && "mb-6")}
              >
                {data.fee && isFromUser && (
                  <div>
                    <div className="mb-0.5 text-sm text-text">
                      Transaction Fees
                    </div>
                    <div className="flex flex-wrap items-center gap-1 text-xs text-danger">
                      <strong className="break-all font-medium tracking-wide">
                        -{data.fee} {config.currency}
                      </strong>
                      <strong className="break-all font-medium tracking-wide">
                        (-${priceEquivalent.equivalent("WBT", data.fee)})
                      </strong>
                    </div>
                  </div>
                )}
                {(data.timestamp || data.created_at) && (
                  <div className="flex items-start gap-4">
                    <div className="grow">
                      <div className="mb-0.5 text-sm text-text">Type:</div>
                      <div className="break-all text-xs font-medium text-foreground">
                        {isFromUser ? "Withdraw" : "Deposit"}
                      </div>
                    </div>
                    {data.timestamp ? (
                      <div className="grow">
                        <div className="mb-0.5 text-sm text-text">Date:</div>
                        <div className="break-all text-xs font-medium text-foreground">
                          {formatTimestamp(data.timestamp)}
                        </div>
                      </div>
                    ) : (
                      <div className="grow">
                        <div className="mb-0.5 text-sm text-text">Date:</div>
                        <div className="break-all text-xs font-medium text-foreground">
                          {formatTimestamp(data.created_at)}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {data.hash && (
                  <div>
                    <div className="mb-0.5 text-sm text-text">Hash:</div>
                    <div className="flex items-center justify-between gap-4 text-xs">
                      <button
                        type="button"
                        className="link text-left transition-colors"
                        onClick={() => handleLinkClick("hash", data.hash!)}
                      >
                        <span className="break-all font-medium tracking-wide">
                          {data.hash}
                        </span>
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          copy(
                            data.hash!,
                            "Transaction hash copied to clipboard",
                          )
                        }
                      >
                        <CopyIcon
                          size="16"
                          className="shrink-0 text-primary transition-colors hover:text-primary/90"
                        />
                      </button>
                    </div>
                  </div>
                )}
                <div>
                  <div className="mb-0.5 text-sm text-text">Sender Address</div>
                  <div className="flex items-center justify-between gap-4 text-xs">
                    <button
                      type="button"
                      className="link text-left transition-colors"
                      onClick={() => handleLinkClick("address", data.from)}
                    >
                      <span className="break-all font-medium tracking-wide">
                        {data.from}
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        copy(
                          data.from!,
                          "Sender address hash copied to clipboard",
                        )
                      }
                    >
                      <CopyIcon
                        size="16"
                        className="shrink-0 text-primary transition-colors hover:text-primary/90"
                      />
                    </button>
                  </div>
                </div>
                <div>
                  <div className="mb-0.5 text-sm text-text">
                    Recipient Address
                  </div>
                  <div className="flex items-center justify-between gap-4 text-xs">
                    <button
                      type="button"
                      className="link text-left transition-colors"
                      onClick={() => handleLinkClick("address", data.to)}
                    >
                      <span className="break-all font-medium tracking-wide">
                        {data.to}
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        copy(data.to!, "Recipient address copied to clipboard")
                      }
                    >
                      <CopyIcon
                        size="16"
                        className="shrink-0 text-primary transition-colors hover:text-primary/90"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {data?.value && isFromUser && (
                <Button asChild className="w-full">
                  <Link
                    to="/withdraw/currency/$currency/submit"
                    params={{ currency: data.unit as Ticker }}
                    search={{ address: data.to as Address, amount: data.value }}
                  >
                    <span>Repeat Transaction</span>
                    <RepeatIcon className="ml-auto" />
                  </Link>
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import { Link } from "@tanstack/react-router";
import { ChevronRight, Loader } from "lucide-react";
import { ComponentPropsWithoutRef, FunctionComponent } from "react";

import { useTransactionsListQuery } from "@/api/queries/transactions";
import { cn } from "@/utils/cn";

import { TransactionItem } from "./transaction-item";

interface HomeTransactionsProps extends ComponentPropsWithoutRef<"div"> {}

const HomeTransactions: FunctionComponent<HomeTransactionsProps> = ({
  className,
  ...props
}) => {
  const { data, isLoading } = useTransactionsListQuery();

  const length = data?.items?.length || 0;
  const isEmpty = length <= 0;

  return (
    <div className={cn("", className)} {...props}>
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-lg font-medium text-foreground">Transactions</h2>
        {!isEmpty && (
          <Link
            to="/transactions"
            className="group flex items-center gap-0.5 text-primary dark:text-secondary"
          >
            <span>See more</span>
            <ChevronRight
              size="24"
              className="transition-transform group-hover:translate-x-0.5"
            />
          </Link>
        )}
      </div>
      <div
        className={cn("min-h-48 rounded-2xl bg-section", isLoading && "p-4")}
      >
        {isLoading ? (
          <Loader
            className="mx-auto mt-14 animate-spin text-primary"
            size="32"
          />
        ) : isEmpty ? (
          <div className="mx-auto max-w-72 p-4 pt-14 text-center">
            <div className="mb-2 font-medium text-foreground">
              You have not transactions yet
            </div>
            <p className="text-sm text-text">
              Ones you make or receive transactions, they will appear here
            </p>
          </div>
        ) : (
          data?.items.map((transaction, index) => (
            <TransactionItem
              transaction={transaction}
              key={`tx-${transaction.hash}-${index}`}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default HomeTransactions;

import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { BackButton, useWebApp } from "@vkruglikov/react-telegram-web-app";
import { ArrowUpRight, ChevronRight } from "lucide-react";

import InfoIcon from "@/assets/icons/info.svg?react";
import ThemeIcon from "@/assets/icons/theme.svg?react";
import WalletIcon from "@/assets/icons/wallet.svg?react";
import { Heading } from "@/components/ui/heading";
import { config } from "@/config";

export const Route = createFileRoute("/settings/")({
  component: SettingsPage,
});

const links = [
  {
    title: "My Addresses",
    href: "/settings/addresses",
    Icon: WalletIcon,
  },
  {
    title: "Themes",
    href: "/settings/themes",
    Icon: ThemeIcon,
  },
];

const externalLinks = [
  {
    title: "Terms and Conditions",
    href: config.termsLink,
    Icon: InfoIcon,
  },
  {
    title: "Privacy Policy",
    href: config.policyLink,
    Icon: InfoIcon,
  },
];

function SettingsPage() {
  const WebApp = useWebApp() as WebApp;
  const navigate = useNavigate();

  return (
    <>
      <BackButton onClick={() => navigate({ to: "/" })} />
      <div className="px-4 py-6">
        <Heading className="mb-4">Settings</Heading>
        <div className="space-y-3">
          <div className="rounded-3xl bg-wrapper">
            {links.map((item, index) => (
              <Link
                to={item.href}
                key={`internal-link-${index}`}
                className="group flex w-full items-center justify-between gap-2 border-b border-border p-4 last:border-none dark:border-background"
              >
                <div className="flex items-center gap-3">
                  <div className="flex size-10 items-center justify-center rounded-full bg-primary">
                    <item.Icon className="text-white" />
                  </div>
                  <span className="text-foreground">{item.title}</span>
                </div>
                <ChevronRight size={24} className="text-text transition-all" />
              </Link>
            ))}
          </div>
          <div className="rounded-3xl bg-wrapper">
            {externalLinks.map((item, index) => (
              <button
                type="button"
                key={`internal-link-${index}`}
                className="group flex w-full grow items-center justify-between gap-2 border-b border-border p-4 last:border-none dark:border-background"
                onClick={() => WebApp.openLink(item.href)}
              >
                <div className="flex items-center gap-3">
                  <div className="flex size-10 items-center justify-center rounded-full bg-primary">
                    <item.Icon className="text-white" />
                  </div>
                  <span className="text-foreground">{item.title}</span>
                </div>
                <ArrowUpRight size="20" className="text-text transition-all" />
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

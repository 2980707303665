import { HTMLAttributes } from "react";

import { cn } from "@/utils/cn";

type HeadingProps = HTMLAttributes<HTMLHeadingElement> & {
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

export function Heading({ tag, className, children, ...props }: HeadingProps) {
  const Component = tag ?? "h1";

  return (
    <Component
      className={cn(
        "text-[32px] font-medium leading-10 text-foreground",
        className,
      )}
      {...props}
    >
      {children}
    </Component>
  );
}

import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import {
  createTransaction,
  estimateCurrencyTransaction,
  fetchTransaction,
  fetchTransactions,
} from "@/api/functions/transactions";
import { CreateTransactionParams } from "@/api/types";

export function useSingleTransactionQuery(txId: string) {
  const query = useQuery({
    queryKey: ["transaction", txId],
    queryFn: () => fetchTransaction(txId),
  });

  return query;
}

export function useTransactionsListQuery() {
  const query = useQuery({
    queryKey: ["transactions"],
    queryFn: () => fetchTransactions({ query: { page_size: 3 } }),
  });

  return query;
}

export function useInfiniteTransactionsQuery() {
  const query = useInfiniteQuery({
    queryKey: ["infinite-transactions"],
    initialPageParam: null as Nullable<string>,
    queryFn: async ({ pageParam, signal }) =>
      fetchTransactions({
        query: { page_token: pageParam, page_size: 10 },
        signal,
      }),
    getNextPageParam: (lastPage) => lastPage.next_page_token,
  });

  const pages = query.data?.pages ?? [];

  return {
    ...query,
    pages,
  };
}

export function useCreateTransaction({
  from,
  to,
}: {
  from: string;
  to: string;
}) {
  const mutation = useMutation({
    mutationKey: ["createTransaction", from, to],
    mutationFn: (params: CreateTransactionParams) => createTransaction(params),
  });

  return mutation;
}

export function useEstimateTransaction() {
  const mutation = useMutation({
    mutationKey: ["estimateCurrencyTransaction"],
    mutationFn: (params: CreateTransactionParams) =>
      estimateCurrencyTransaction(params),
  });

  return mutation;
}

import { FetchOptions } from "ofetch";

import { fetcher } from "@/api";
import { CreateTransactionParams, Transaction } from "@/api/types";
import { getUserId } from "@/utils/userId";

export function fetchTransactions(options?: FetchOptions<"json">) {
  return fetcher<ListResponse<Transaction>>(
    `/users/${getUserId()}/transactions`,
    options,
  );
}

export function fetchTransaction(txId: string) {
  return fetcher<Transaction>(`/users/${getUserId()}/transactions/${txId}`);
}

export function estimateCurrencyTransaction(params: CreateTransactionParams) {
  return fetcher<{ fee: string }>(`/fees/estimate/transaction/by-currency`, {
    method: "POST",
    body: params,
  });
}

export function createTransaction(params: CreateTransactionParams) {
  return fetcher<Transaction>(`/users/${getUserId()}/transactions`, {
    method: "POST",
    body: params,
  });
}

import { useQuery } from "@tanstack/react-query";
import { Numeric } from "decimal.js-light";
import { useCallback, useMemo } from "react";

import { getBalanceByAddress } from "@/api/functions/balances";
import { useAddressesQuery } from "@/api/queries/addresses";
import { formatBalance, safeDecimal } from "@/utils/numbers";

import { useCurrencies } from "./currencies";

export function useBalances({ format } = { format: true }) {
  const { currenciesArray } = useCurrencies();
  const { primary } = useAddressesQuery();

  const query = useQuery({
    queryKey: ["balances", primary],
    refetchInterval: 1000 * 60,
    refetchIntervalInBackground: true,
    queryFn: () => getBalanceByAddress(primary),
    enabled: !!primary,
  });

  const _balances = useMemo(() => {
    return currenciesArray.reduce(
      (acc, currency) => {
        const balance = query.data?.[currency.id] ?? "0";

        acc[currency.ticker] = format ? formatBalance(balance) : balance;

        return acc;
      },
      {} as Record<Ticker, string>,
    );
  }, [currenciesArray, format, query.data]);

  return {
    balances: _balances,
    ...query,
  };
}

export function useEquivalent() {
  const { currencies } = useCurrencies();
  const { balances } = useBalances();

  const equivalent = useCallback(
    (ticker: Ticker, amount: Numeric) => {
      if (!amount) return formatBalance(0);

      const price = safeDecimal(currencies?.[ticker]?.last_price_usd);
      const decimal = safeDecimal(amount);

      return formatBalance(decimal.mul(price), 4);
    },
    [currencies],
  );

  const balance = useMemo(
    () => equivalent("WBT", balances.WBT),
    [equivalent, balances],
  );

  return {
    equivalent,
    balance,
  };
}

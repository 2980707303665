import { ComponentPropsWithoutRef, FunctionComponent } from "react";

import { getTickerImage } from "@/api";
import { cn } from "@/utils/cn";

interface TickerImageProps extends ComponentPropsWithoutRef<"img"> {
  ticker: Ticker;
}

const TickerImage: FunctionComponent<TickerImageProps> = ({
  ticker,
  className,
  ...props
}) => {
  const url = getTickerImage(ticker);

  return (
    <img src={url} alt={ticker} className={cn("", className)} {...props} />
  );
};

export { TickerImage };

import { fetcher } from "@/api";
import { User } from "@/api/types";
import { getUserId } from "@/utils/userId";

export const acceptTerms = () =>
  fetcher<User>(`/users/${getUserId()}/tnc`, {
    method: "POST",
  });

export const fetchUser = (bearer?: string) =>
  fetcher<User>(`/users/${getUserId()}`, {
    method: "GET",
    headers: bearer
      ? {
          Authorization: `Bearer ${bearer}`,
        }
      : {},
  });

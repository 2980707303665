export function truncateString(string: string, length = 8) {
  if (!string) return "";

  if (string.length <= length) return string;

  const halfLength = Math.floor(length / 2);

  const head = string.slice(0, halfLength);
  const tail = string.slice(string.length - halfLength);

  return `${head}...${tail}`;
}

import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { ComponentPropsWithoutRef, FunctionComponent } from "react";

import { cn } from "@/utils/cn";

interface HomeGamesProps extends ComponentPropsWithoutRef<"button"> {}

const HomeGames: FunctionComponent<HomeGamesProps> = ({
  className,
  ...props
}) => {
  const webApp = useWebApp() as WebApp;

  function handleClick() {
    webApp.openTelegramLink(import.meta.env.VITE_GAME_URL);
  }

  return (
    <button
      type="button"
      onClick={() => handleClick()}
      className={cn(
        "relative block h-full overflow-hidden rounded-2xl p-4 before:absolute before:left-0 before:top-0 before:z-10 before:h-full before:w-full before:rounded-2xl before:bg-gradient-to-l before:from-black before:via-black before:via-10% before:to-transparent",
        className,
      )}
      {...props}
    >
      <img
        src="/images/pocket-rocket.webp"
        alt="Pocket Rocket"
        className="absolute left-0 top-0 z-0 h-full w-full object-cover object-[-40px_85%] transition-transform group-hover:scale-105"
      />
      <div className="relative z-10 ml-auto w-1/2 max-w-[162px]">
        <p className="mb-1 text-lg text-white">Pocket Rocket</p>
        <p className="text-xs text-white">
          Turn every action into rewards and reap benefits from day one.
        </p>
      </div>
    </button>
  );
};

export default HomeGames;

import { ofetch } from "ofetch";

import { AuthResponse } from "@/api/types/auth";
import { config } from "@/config";

export function authenticate(initData: string) {
  return ofetch<AuthResponse>(`${config.apiUrl}/auth`, {
    method: "POST",
    body: { hash: initData },
    credentials: "include",
  });
}

export function refreshToken(token: string) {
  return ofetch<{ jwt: string }>(`${config.apiUrl}/jwt`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: "include",
  });
}

import { useCopyToClipboard as useReactUseCopyToClipboard } from "react-use";
import { toast, ToastT } from "sonner";

export const useCopyToClipboard = () => {
  const [, copy] = useReactUseCopyToClipboard();

  return (value: string, text?: string, options?: ToastT) => {
    copy(value);
    toast.info(text || `${value} copied to clipboard`, {
      duration: 2000,
      ...options,
    });
  };
};

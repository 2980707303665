import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { fetchCurrency } from "@/api/functions/currencies";

import { Currency } from "../types";

export const useCurrencies = () => {
  const query = useQuery({
    queryKey: ["currency"],
    queryFn: () => fetchCurrency(),
  });

  const tickers = useMemo(
    () => Object.keys(query.data || {}) as Ticker[],
    [query.data],
  );

  const currencies = useMemo(
    () =>
      tickers.reduce(
        (acc, ticker) => {
          const currency = query.data?.[ticker];

          if (!currency) return acc;
          if (!currency.is_used_for_transfer) return acc;

          return {
            ...acc,
            [ticker]: currency,
          };
        },
        {} as Record<Ticker, Currency>,
      ),
    [tickers, query.data],
  );

  const currenciesForCalculation = useMemo(
    () =>
      tickers.reduce(
        (acc, ticker) => {
          const currency = query.data?.[ticker];

          if (!currency) return acc;
          if (!currency.is_used_for_rate_calculation) return acc;

          return {
            ...acc,
            [ticker]: currency,
          };
        },
        {} as Record<Ticker, Currency>,
      ),
    [tickers, query.data],
  );

  const currenciesArray = useMemo(
    () =>
      Object.values(currencies).sort((a, b) => {
        if (a.sort_order > b.sort_order) return 1;
        if (a.sort_order < b.sort_order) return -1;

        return 0;
      }),
    [currencies],
  );

  return { currencies, currenciesForCalculation, currenciesArray, ...query };
};

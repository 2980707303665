import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  BackButton,
  ColorScheme,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";
import { useLocalStorage } from "react-use";

import { Heading } from "@/components/ui/heading";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/utils/cn";

export const Route = createFileRoute("/settings/themes/")({
  component: ThemePage,
});

function ThemePage() {
  const WebApp = useWebApp() as WebApp;
  const navigate = useNavigate();

  const [localTheme, setLocalTheme] = useLocalStorage("theme", "system", {
    raw: true,
  });

  const handleThemeChange = (theme: ColorScheme | "system") => {
    setLocalTheme(theme);

    const html = document.querySelector("html");

    html!.classList.remove("light", "dark");

    if (theme !== "system") {
      html!.classList.add(theme as string);

      WebApp.setBackgroundColor(theme === "dark" ? "#080a28" : "#68ecbc");
      WebApp.setHeaderColor(theme === "dark" ? "#080a28" : "#68ecbc");
    } else {
      html!.classList.add(WebApp.colorScheme as string);

      WebApp.setBackgroundColor(
        WebApp.colorScheme === "dark" ? "#080a28" : "#68ecbc",
      );
      WebApp.setHeaderColor(
        WebApp.colorScheme === "dark" ? "#080a28" : "#68ecbc",
      );
    }
  };

  return (
    <>
      <BackButton onClick={() => navigate({ to: "/settings" })} />
      <div className="px-4 py-6">
        <Heading className="mb-4">Themes</Heading>
        <div className="rounded-3xl bg-wrapper p-4">
          <RadioGroup
            value={localTheme}
            asChild
            name="theme"
            onValueChange={(value) => handleThemeChange(value as ColorScheme)}
          >
            <div className="grid grid-cols-[148px,148px] justify-center gap-4">
              <div className="flex w-full flex-col items-center justify-center gap-2">
                <div
                  className={cn(
                    "cursor-pointer overflow-hidden rounded-2xl border-2 border-transparent p-0.5",
                    localTheme === "dark" && "border-primary",
                  )}
                  onClick={() => handleThemeChange("dark")}
                >
                  <img
                    src="/images/settings/dark.png?v=2"
                    alt="Dark theme"
                    className="h-40"
                  />
                </div>
                <RadioGroupItem value="dark" />
              </div>
              <div className="flex w-full flex-col items-center justify-center gap-2">
                <div
                  className={cn(
                    "cursor-pointer overflow-hidden rounded-xl border-2 border-transparent p-0.5",
                    localTheme === "light" && "border-primary",
                  )}
                  onClick={() => handleThemeChange("light")}
                >
                  <img
                    src="/images/settings/light.png?v=2"
                    alt="Light theme"
                    className="h-40"
                  />
                </div>
                <RadioGroupItem value="light" />
              </div>
              <div className="flex w-full flex-col items-center justify-center gap-2">
                <div
                  className={cn(
                    "cursor-pointer overflow-hidden rounded-xl border-2 border-transparent p-0.5",
                    localTheme === "system" && "border-primary",
                  )}
                  onClick={() => handleThemeChange("system")}
                >
                  <img
                    src="/images/settings/system.png"
                    alt="System theme"
                    className="h-40"
                  />
                </div>
                <RadioGroupItem value="system" />
              </div>
            </div>
          </RadioGroup>
        </div>
      </div>
    </>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import {
  createFileRoute,
  useNavigate,
  useParams,
  useSearch,
} from "@tanstack/react-router";
import {
  BackButton,
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";
import { Loader, Loader2 } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Img } from "react-image";
import { isAddress } from "viem";
import { z } from "zod";

import { useAddressesQuery } from "@/api/queries/addresses";
import { useEquivalent } from "@/api/queries/balances";
import {
  useEstimateNFTTransaction,
  useNFT,
  useNFTBalancesQuery,
  useNFTTransfer,
} from "@/api/queries/nft";
import ArrowRight from "@/assets/icons/arrow-right.svg?react";
import MinusIcon from "@/assets/icons/minus.svg?react";
import PlusIcon from "@/assets/icons/plus.svg?react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { config } from "@/config";
import { onKeyDownFloat } from "@/utils/input";
import { formatNumber } from "@/utils/numbers";
import { truncateString } from "@/utils/strings";

const paramsSchema = z.object({
  contract: z.string(),
  tokenId: z.string(),
});

const searchSchema = z.object({
  address: z
    .string()
    .min(1, "Address is required")
    .refine(isAddress, "Address is invalid"),
});

export const Route = createFileRoute(
  "/withdraw/nft/$contract/$tokenId/_layout/submit",
)({
  component: WithdrawNFTSubmitPage,
  parseParams: paramsSchema.parse,
  validateSearch: searchSchema,
});

function WithdrawNFTSubmitPage() {
  const WebApp = useWebApp() as WebApp;
  const navigate = useNavigate();
  const [impact] = useHapticFeedback();

  const { contract, tokenId } = useParams({
    from: "/withdraw/nft/$contract/$tokenId/_layout/submit",
  });

  const { address } = useSearch({
    from: "/withdraw/nft/$contract/$tokenId/_layout/submit",
  });

  const { equivalent } = useEquivalent();
  const { primary } = useAddressesQuery();
  const { data: nft } = useNFT({ contract, tokenId, owner: primary });

  const { data: nfts } = useNFTBalancesQuery({
    enabled: nft?.type === "wrc1155",
  });

  const { mutateAsync } = useEstimateNFTTransaction();

  const [fee, setFee] = useState("");

  const balance = useMemo(() => {
    if (!nfts || nft?.type === "wrc721") return "1";

    return nft?.amount;
  }, [nfts, nft]);

  const { mutateAsync: transferNFT, isPending } = useNFTTransfer();

  const [quantity, setQuantity] = useState(1);

  const formSchema = useMemo(
    () =>
      z.object({
        quantity: z
          .string()
          .min(1, "Quantity must be at least 1")
          .refine((v) => +v > 0, "Quantity must be at least 1")
          .refine(
            (v) => +v <= Number(balance),
            "Quantity must be less than or equal to the total available",
          ),
      }),
    [balance],
  );

  const { formState, register, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      quantity: "1",
    },
    resolver: zodResolver(formSchema),
  });

  function changeQuantity(amount: number) {
    impact("light");
    setQuantity(amount);
    setValue("quantity", amount.toString());
  }

  function handleChangeQuantity(amount: number) {
    const _quantity = Math.ceil(Number(quantity) + amount);

    if (_quantity >= 1 && _quantity <= Number(balance)) {
      changeQuantity(_quantity);
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    await transferNFT(
      {
        contract,
        token_id: tokenId,
        to: address,
        type: nft!.type,
        quantity: data.quantity,
      },
      { onSuccess: () => WebApp.close() },
    );
  });

  useEffect(() => {
    async function estimate() {
      const data = await mutateAsync({
        from: primary,
        to: address,
        contract: nft!.contract,
        method: "transfer",
        token_id: nft!.tokenId,
        type: nft!.type,
        quantity: quantity.toString(),
      });

      setFee(data.fee);
    }

    if (!nft || !primary || quantity <= 0) return;

    estimate();
  }, [nft, primary, quantity]);

  return (
    <>
      <BackButton
        onClick={() =>
          navigate({
            to: "/withdraw/nft/$contract/$tokenId",
            params: { contract, tokenId },
            search: { address },
          })
        }
      />
      <form
        className="mx-auto mb-4 max-w-[340px] rounded-4xl bg-wrapper p-3 pb-6"
        onSubmit={onSubmit}
      >
        <div className="mb-4 space-y-4 rounded-2xl bg-section p-3 pb-4">
          <h1 className="text-center text-lg font-medium text-foreground">
            Transaction Preview
          </h1>
          <div className="mb-1 flex min-h-[150px] items-center justify-center">
            <Img
              src={nft?.imageUrl ?? ""}
              className="size-[150px] overflow-hidden rounded-2xl object-cover"
              unloader={
                <Img
                  src="/empty-nft.svg"
                  alt="NFT"
                  className="size-[150px] overflow-hidden rounded-2xl object-cover"
                />
              }
              loader={
                <Loader
                  className="animate-spin text-primary"
                  width={24}
                  height={24}
                />
              }
            />
          </div>
          {nft?.type === "wrc1155" && (
            <div className="flex items-end gap-2">
              <button
                type="button"
                className="flex size-10 shrink-0 items-center justify-center rounded-full bg-button-foreground dark:bg-secondary"
                onClick={() => handleChangeQuantity(-1)}
              >
                <MinusIcon className="text-white dark:text-black" />
              </button>
              <div>
                <div className="mb-0.5 flex items-center justify-between">
                  <label htmlFor="quantity" className="text-sm">
                    Total available
                  </label>
                  <button
                    type="button"
                    className="font-medium text-primary dark:text-secondary"
                    onClick={() => changeQuantity(Number(balance))}
                  >
                    {balance}
                  </button>
                </div>
                <Input
                  id="quantity"
                  type="text"
                  data-precision="0"
                  inputMode="decimal"
                  onKeyDown={onKeyDownFloat}
                  placeholder="Enter quantity"
                  {...register("quantity")}
                />
              </div>
              <button
                type="button"
                className="flex size-10 shrink-0 items-center justify-center rounded-full bg-button-foreground dark:bg-secondary"
                onClick={() => handleChangeQuantity(1)}
              >
                <PlusIcon className="text-white dark:text-black" />
              </button>
            </div>
          )}
          {formState.errors?.quantity && (
            <p className="text-center text-xs text-red-500">
              {formState.errors.quantity.message}
            </p>
          )}
        </div>
        <div className="px-3">
          <div className="mb-4 text-left">
            <p className="text-sm text-text">Transaction fees</p>
            <p className="text-xs font-medium">
              {fee ? (
                <span className="text-danger">
                  -{formatNumber(fee, 8)} {config.currency} (-$
                  {equivalent(config.currency, fee)})
                </span>
              ) : (
                <Loader2 className="animate-spin" size={12} />
              )}
            </p>
          </div>

          <div className="mb-3">
            <p className="text-sm text-text">Collection</p>
            <p className="break-all text-xs font-medium text-foreground">
              {nft?.name || nft?.contract}
            </p>
          </div>
          <div v-if="nft?.tokenId" className="mb-3">
            <p className="text-sm text-text">Token ID</p>
            <p className="break-all text-xs font-medium text-foreground">
              {nft?.tokenId}
            </p>
          </div>
          <div className="mb-4 grid grid-cols-[1fr,24px,1fr] items-center gap-1">
            <div>
              <p className="text-sm text-text">Sender Address</p>
              <p className="text-xs font-medium text-foreground">
                {truncateString(primary!)}
              </p>
            </div>
            <div className="">
              <ArrowRight className="text-primary dark:text-secondary" />
            </div>
            <div className="text-right">
              <p className="text-sm text-text">Recipient Address</p>
              <p className="text-xs font-medium text-foreground">
                {truncateString(address)}
              </p>
            </div>
          </div>
          <Button
            className="mb-4 w-full"
            disabled={isPending}
            loading={isPending}
            type="submit"
          >
            Continue
          </Button>
          <p className="text-center text-sm text-warning">
            Ensure the transaction details are correct. Indicating incorrect
            recipient data can lead to the irretrievable loss of your assets
          </p>
        </div>
      </form>
    </>
  );
}

import { FetchOptions } from "ofetch";

import { fetcher } from "@/api";
import { NFT, NFTListItem, TransferNFTPayload } from "@/api/types";
import { getUserId } from "@/utils/userId";

export function fetchNFTs(address: string, options?: FetchOptions<"json">) {
  return fetcher<ListResponse<NFTListItem>>(
    `/users/${getUserId()}/wallets/${address ?? "-"}/nft`,
    options,
  );
}

export function fetchNFT(
  contract: string,
  tokenId: string,
  options?: FetchOptions<"json">,
) {
  return fetcher<NFT>(`/nft/${contract}/${tokenId}`, options);
}

export function transferNFT(
  body: TransferNFTPayload,
  options?: FetchOptions<"json">,
) {
  return fetcher(`/users/${getUserId()}/nft`, {
    method: "POST",
    body,
    ...options,
  });
}

export function estimateNFTTransaction(params: TransferNFTPayload) {
  return fetcher<{ fee: string }>(`/fees/estimate/nft`, {
    method: "POST",
    body: params,
  });
}

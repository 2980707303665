import { useQueryClient } from "@tanstack/react-query";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { useState } from "react";

import { isApiError } from "@/api";
import { useAcceptTerms } from "@/api/queries/user";
import { Button } from "@/components/ui/button";
import { config } from "@/config";

export function Terms() {
  const queryClient = useQueryClient();
  const { openLink } = useWebApp() as WebApp;

  const { acceptTerms, isPending, error } = useAcceptTerms({
    onSuccess: (user) => {
      if (user.tnc_timestamp) {
        queryClient.setQueryData(["user"], user);
      }
    },
  });

  const [isAccepted, setIsAccepted] = useState(false);

  return (
    <div>
      <label className="mb-6 flex items-center gap-2.5">
        <input
          type="checkbox"
          onChange={(event) => setIsAccepted(event.target.checked)}
          className="h-6 w-6 shrink-0 appearance-none rounded border border-border bg-white bg-[url('/check.svg')] bg-center bg-no-repeat text-foreground checked:border-transparent checked:bg-primary dark:border-transparent"
        />
        <div className="text-left text-sm text-text">
          I agree to the
          <button
            className="link mx-0.5 text-sm underline"
            onClick={() => openLink(config.termsLink)}
          >
            Terms and Conditions
          </button>
          and
          <button
            className="link mx-0.5 text-sm underline"
            onClick={() => openLink(config.policyLink)}
          >
            Privacy Policy
          </button>
        </div>
      </label>
      <Button
        className="mb-4 w-full"
        onClick={() => acceptTerms()}
        loading={isPending}
        disabled={!isAccepted}
      >
        Get started
      </Button>
      {error && (
        <p className="pt-2 text-sm text-danger">
          <span className="block uppercase">
            {isApiError(error) ? error.data?.code : "Error:"}
          </span>
          <span className="block">Try to clear application cache</span>
        </p>
      )}
    </div>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import {
  createFileRoute,
  useNavigate,
  useParams,
  useSearch,
} from "@tanstack/react-router";
import {
  BackButton,
  useScanQrPopup,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";
import { X as XIcon } from "lucide-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Address, checksumAddress, isAddress } from "viem";
import { z } from "zod";

import { useCurrencies } from "@/api/queries/currencies";
import ScanIcon from "@/assets/icons/scan.svg?react";
import { Button } from "@/components/ui/button";

const search = z.object({
  address: z.string().optional(),
  backUrl: z.string().optional(),
});

const paramsSchema = z.object({
  currency: z.string(),
});

export const Route = createFileRoute("/withdraw/currency/$currency/_layout/")({
  component: WithdrawCurrencyPage,
  validateSearch: search,
  parseParams: paramsSchema.parse,
});

const formSchema = z.object({
  address: z
    .string()
    .min(1, "Address is required")
    .transform((value) => checksumAddress(value as Address))
    .refine((value) => isAddress(value), {
      message: "Invalid address",
    }),
});

function WithdrawCurrencyPage() {
  const WebApp = useWebApp() as WebApp;
  const [showPopup, closePopup] = useScanQrPopup();

  const { currencies } = useCurrencies();
  const navigate = useNavigate();

  const params = useParams({
    from: "/withdraw/currency/$currency/_layout/",
  });

  const { address, backUrl } = useSearch({
    from: "/withdraw/currency/$currency/_layout/",
  });

  if (
    !params.currency ||
    currencies?.[params.currency as Ticker] === undefined
  ) {
    navigate({
      to: "/withdraw/currency/",
    });
  }

  const { formState, getValues, register, setValue, handleSubmit, reset } =
    useForm({
      resolver: zodResolver(formSchema),
      defaultValues: { address: address as Address },
      mode: "onChange",
    });

  const onSubmit: SubmitHandler<{ address: Address }> = (data) => {
    navigate({
      to: "/withdraw/currency/$currency/amount",
      search: { address: data.address },
      params,
    });
  };

  return (
    <>
      <BackButton
        onClick={() =>
          navigate({
            to: backUrl || "/",
          })
        }
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="mb-2 break-all text-center text-lg font-medium text-foreground">
          Send {params.currency} to address
        </h1>
        <p className="mb-3 text-center text-text">
          Make sure you do not withdraw your token to a smart contract
        </p>
        <label htmlFor="address" className="mb-1 block text-text">
          Address
        </label>
        <div className="mb-4">
          <div className="flex h-12 items-center rounded-lg border border-border pr-4 transition-colors focus-within:border-primary">
            <input
              id="address"
              placeholder="0x..."
              className="h-full grow bg-transparent pl-4 text-foreground outline-none"
              {...register("address")}
            />
            {getValues("address") && (
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  reset();
                }}
                className="shrink-0"
              >
                <XIcon className="text-foreground transition-colors" />
              </button>
            )}
            {(WebApp.platform.includes("ios") ||
              WebApp.platform.includes("android")) && (
              <button
                type="button"
                className="shrink-0"
                onClick={() =>
                  showPopup({ text: "Scan your WBT address" }, (address) => {
                    setValue("address", address as Address);
                    closePopup();
                  })
                }
              >
                <ScanIcon className="text-foreground transition-colors" />
              </button>
            )}
          </div>
          {formState.errors.address && (
            <div className="pt-1 text-sm text-red-500">
              {formState.errors.address.message}
            </div>
          )}
        </div>
        <Button
          loading={formState.isSubmitting}
          className="w-full justify-center"
          type="submit"
        >
          Continue
        </Button>
      </form>
    </>
  );
}

import { Loader } from "lucide-react";
import { ComponentPropsWithoutRef, FunctionComponent } from "react";

import { useCurrencies } from "@/api/queries/currencies";
import { cn } from "@/utils/cn";

import { CurrencyItem } from "./currency-item";

interface HomeCurrenciesProps extends ComponentPropsWithoutRef<"div"> {}

const HomeCurrencies: FunctionComponent<HomeCurrenciesProps> = ({
  className,
  ...props
}) => {
  const { currenciesArray, isLoading } = useCurrencies();

  return (
    <div className={cn("", className)} {...props}>
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-lg font-medium text-foreground">Assets</h2>
      </div>
      <div className="min-h-32 rounded-2xl bg-section">
        {isLoading ? (
          <Loader
            className="mx-auto mt-14 animate-spin text-primary"
            size="32"
          />
        ) : (
          currenciesArray.map((currency, index) => (
            <CurrencyItem
              currency={currency}
              key={`currency-${currency.ticker}-${index}`}
            />
          ))
        )}
      </div>
    </div>
  );
};

export { HomeCurrencies };

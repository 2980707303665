import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { BackButton, useWebApp } from "@vkruglikov/react-telegram-web-app";
import { useMemo } from "react";

import { fetchAddresses } from "@/api/functions/addresses";
import { useAddressesQuery } from "@/api/queries/addresses";
import Copy from "@/assets/icons/copy.svg?react";
import Share from "@/assets/icons/share.svg?react";
import { Button } from "@/components/ui/button";
import { getTickerIconURI } from "@/config";
import { useCopyToClipboard } from "@/utils/copy";

export const Route = createFileRoute("/deposit/")({
  component: DepositPage,
  loader: async ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData({
      queryKey: ["addresses"],
      queryFn: () => fetchAddresses(),
    });
  },
});

function DepositPage() {
  const WebApp = useWebApp() as WebApp;

  const navigate = useNavigate();
  const copy = useCopyToClipboard();

  const { primary } = useAddressesQuery();

  const qr = useMemo(
    () =>
      `https://bff.whitebit.com/v1/canvas/qr?url=${primary}&black=true&size=1000&image=${getTickerIconURI("WBT")}`,
    [primary],
  );

  function onShareClick() {
    if (window.navigator.share) {
      window.navigator.share({
        text: `Here is my Whitechain address: ${primary}`,
      });
    } else {
      WebApp.openTelegramLink(
        `https://t.me/share/url?url=https://t.me/whitechain_wallet_bot&text=${encodeURIComponent(`Here is my Whitechain address: \`${primary}\``)}`,
      );
    }
  }

  return (
    <>
      <BackButton
        onClick={() => navigate({ to: "/", search: { backUrl: "" } })}
      />
      <div className="px-4 py-10">
        <img
          src="/whitechain-logo.svg"
          alt="Whitechain Logo"
          className="mx-auto mb-4 block dark:hidden"
        />
        <img
          src="/whitechain-logo-dark.svg"
          alt="Whitechain Logo Dark"
          className="mx-auto mb-4 hidden dark:block"
        />
        <div className="mx-auto max-w-[340px] rounded-4xl bg-wrapper p-6">
          <h1 className="mb-2 text-center text-lg font-medium text-foreground">
            Receive
          </h1>
          <p className="mb-3 text-center text-text">
            Send WBT and tokens from Whitechain network
          </p>
          <div className="mb-6 break-all text-center font-medium text-primary dark:text-secondary">
            {primary}
          </div>
          <div className="mx-auto mb-6 size-52 rounded-lg border-8 border-white">
            <img src={qr} className="object-contain" />
          </div>
          <div className="flex items-center gap-4">
            <Button
              className="grow"
              onClick={() => copy(primary, `Address copied to clipboard`)}
            >
              <span>Copy</span>
              <Copy className="ml-auto text-white" />
            </Button>
            <Button className="grow" onClick={onShareClick}>
              <span>Share</span>
              <Share className="ml-auto text-white" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

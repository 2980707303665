import { createFileRoute,Outlet } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/withdraw/nft/$contract/$tokenId/_layout",
)({
  component: WithdrawNFTLayout,
});

function WithdrawNFTLayout() {
  return (
    <div className="px-4 py-10">
      <img
        src="/whitechain-logo.svg"
        alt="Whitechain Logo"
        className="mx-auto mb-4 block dark:hidden"
      />
      <img
        src="/whitechain-logo-dark.svg"
        alt="Whitechain Logo Dark"
        className="mx-auto mb-4 hidden dark:block"
      />
      <Outlet />
    </div>
  );
}

import type { FetchOptions } from "ofetch";

import { fetcher } from "@/api";
import { getUserId } from "@/utils/userId";

export function fetchAddresses(options?: FetchOptions<"json">) {
  return fetcher<{ addresses: string[] }>(
    `/users/${getUserId()}/wallets`,
    options,
  );
}

export function createNewAddress(options?: FetchOptions<"json">) {
  return fetcher<{ addresses: string[] }>(`/users/${getUserId()}/wallets`, {
    method: "POST",
    ...options,
  });
}

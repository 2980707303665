import { Link } from "@tanstack/react-router";
import { HTMLAttributes, memo, useState } from "react";

import { NFTStandard } from "@/api/types";
import { cn } from "@/utils/cn";

interface NFTItemProps extends HTMLAttributes<"a"> {
  nft: {
    contract: string;
    tokenId: string;
    type: NFTStandard;
    imageUrl?: Nullable<string>;
    name?: Nullable<string>;
  };
  className?: string;
  backUrl?: string;
}

function Image({ url }: { url?: Nullable<string> }) {
  const [error, setError] = useState(false);

  return error || !url ? (
    <img
      src="/empty-nft.svg"
      alt="NFT"
      className="aspect-square size-full object-cover transition-transform group-hover:scale-110"
    />
  ) : (
    <img
      src={url}
      alt="NFT"
      className="aspect-square size-full object-cover transition-transform group-hover:scale-110"
      onError={() => setError(true)}
    />
  );
}

export const NftItem = memo(({ nft, backUrl, className }: NFTItemProps) => {
  const name = nft.name
    ? `${nft.name} #${nft.tokenId}`
    : `Token #${nft.tokenId}`;

  return (
    <Link
      to="/nft/$contract/$tokenId/"
      params={{ tokenId: nft.tokenId, contract: nft.contract }}
      search={{ backUrl }}
      className={cn("group flex flex-col", className)}
    >
      <div className="mb-1 flex w-full items-center justify-center overflow-hidden rounded-xl bg-wrapper sm:h-40">
        <Image url={nft.imageUrl} />
      </div>
      <strong className="block max-w-full truncate text-sm font-medium text-foreground">
        {name}
      </strong>
      <p className="text-xs text-text">{nft.type.toUpperCase()}</p>
    </Link>
  );
});

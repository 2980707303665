import { ChangeEvent, KeyboardEvent } from "react";

import { parseExponent } from "@/utils/numbers";

export const onChangeCovertDecimal = (
  e: ChangeEvent<HTMLInputElement>,
  defaultValue = "",
) => {
  const precision =
    e.target.dataset.precision !== undefined &&
    e.target.dataset.precision !== null;

  if (precision) {
    e.target.value = e.target.value
      .replace(/[^\d-,./e]/gi, "")
      .replace(/[,/]/g, ".");

    if (e.target.value.includes("e"))
      e.target.value = parseExponent(e.target.value);

    if (+precision === 0) e.target.value = e.target.value.replaceAll(".", "");

    if (e.target.value.startsWith(".")) {
      e.target.value = `0${e.target.value.slice(0, +precision === -1 ? 9 : +precision! + 1)}`;

      setTimeout(() => {
        e.target.setSelectionRange(2, 2);
      }, 0);
    }

    if (
      !/^\d+(?:\\.)?\d*$/.test(
        e.target.value.replaceAll(/[$()*+.?[\\\]^{|}]/g, "\\$&"),
      )
    ) {
      e.target.value = e.target.value.length === 0 ? "" : defaultValue;
      return;
    }
  }
};

export const onKeyDownFloat = (e: KeyboardEvent<HTMLInputElement>) => {
  if (e.metaKey) return;

  const target = e.target as HTMLInputElement;

  const value = target.value as string;
  const caret = target.selectionStart ?? 0;
  const precision = Number(target.dataset.precision);

  const shouldCheckPrecision = !Number.isNaN(precision) && precision > 0;
  const dotIndex = value.indexOf(".");

  if (
    (e.key === "." || e.key === "," || e.key === "/") &&
    (value.includes(".") ||
      value.includes(",") ||
      value.includes("/") ||
      precision === 0)
  ) {
    return e.preventDefault();
  } else if (e.key === "Unidentified") {
    target.value = target.value.replace(/[,/]/, ".");

    const indexOfComma = target.value.indexOf(".");

    if (indexOfComma !== -1) {
      const lastInputChar = target.value[(target.selectionStart ?? -1) - 1];
      if (lastInputChar === ".") {
        target.value =
          target.value.slice(0, (target.selectionStart ?? -1) - 1) +
          target.value.slice(target.selectionStart ?? -1);
        return e.preventDefault();
      }
    }
  }

  if (
    e.key === "." ||
    e.key === "," ||
    e.key === "/" ||
    [46, 8, 9, 27, 13, 110].includes(e.keyCode) ||
    (/65|67|86|88/.test(e.key) && e.ctrlKey === true && !0 === e.ctrlKey) ||
    (e.keyCode >= 35 && e.keyCode <= 40)
  )
    return;

  if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105))
    return e.preventDefault();

  if (
    shouldCheckPrecision &&
    caret > dotIndex &&
    caret === target.selectionEnd
  ) {
    const sides = target.value?.split(".");
    const enteredPrecision = (sides[1] || []).length + 1;

    if (enteredPrecision > precision) {
      if (enteredPrecision - 1 === precision && caret - 1 === enteredPrecision)
        return e.preventDefault();

      target.value = target.value.slice(0, -1);
      target.setSelectionRange(caret, caret);
    }
  }

  if (!/[\d,.-]/.test(e.key)) return e.preventDefault();
};

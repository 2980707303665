import { ComponentPropsWithoutRef, FunctionComponent } from "react";

import { useBalances, useEquivalent } from "@/api/queries/balances";
import { Currency } from "@/api/types";
import { cn } from "@/utils/cn";

import { TickerImage } from "../ui/ticker-image";

interface CurrencyItemProps extends ComponentPropsWithoutRef<"div"> {
  asLink?: boolean;
  currency: Currency;
}

const CurrencyItem: FunctionComponent<CurrencyItemProps> = ({
  asLink,
  currency,
  className,
  ...props
}) => {
  const { balances } = useBalances();
  const { equivalent } = useEquivalent();

  return (
    <div
      className={cn(
        "flex items-center gap-3 border-b border-wrapper p-4 last:border-none",
        className,
      )}
      {...props}
    >
      <TickerImage ticker={currency.ticker} className="size-8 shrink-0" />
      <div>
        <div className="mb-0.5 text-sm font-medium text-foreground">
          {currency.ticker}
        </div>
        <div className="text-xs text-text">{currency.name}</div>
      </div>
      <div className="ml-auto text-right">
        <div className="mb-0.5 text-sm font-medium text-foreground">
          {balances[currency.ticker]}
        </div>
        <div className="text-xs text-text">
          &asymp; ${equivalent(currency.ticker, balances[currency.ticker])}
        </div>
      </div>
    </div>
  );
};

export { CurrencyItem };

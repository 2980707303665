import { getUserId } from "@/utils/userId";

const getStorageKey = () => {
  const id = getUserId();

  return id ? `jwt-${id}` : "jwt";
};

export function getJWT() {
  return localStorage.getItem(getStorageKey());
}

export function setJWT(jwt: string) {
  return localStorage.setItem(getStorageKey(), jwt);
}

export function removeJWT() {
  return localStorage.removeItem(getStorageKey());
}

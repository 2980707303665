import { getUserId } from "@/utils/userId";

const getQueryIdStorageKey = () => {
  const id = getUserId();

  return id ? `queryId-${id}` : "queryId";
};

export function getQueryId() {
  return localStorage.getItem(getQueryIdStorageKey());
}

export function setQueryId(queryId: string) {
  return localStorage.setItem(getQueryIdStorageKey(), queryId);
}

export function removeQueryId() {
  return localStorage.removeItem(getQueryIdStorageKey());
}

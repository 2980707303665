import { FetchError } from "ofetch";

export function DefaultError({ error }: { error: unknown }) {
  if (error instanceof FetchError) {
    return (
      <div className="flex h-full flex-1 flex-col items-center justify-center">
        <h1 className="mb-4 text-center text-4xl font-medium text-foreground">
          Error has been occurred
        </h1>
        <p className="text-center text-text">Please, reload the application</p>
      </div>
    );
  }

  if (error instanceof Error) {
    return (
      <div className="flex h-full flex-1 flex-col items-center justify-center">
        <h1 className="mb-4 text-center text-4xl font-medium text-foreground">
          Error has been occurred
        </h1>
        <p className="text-center text-text">{error.message}</p>
      </div>
    );
  }

  return JSON.stringify(error);
}

import Decimal, { Numeric } from "decimal.js-light";

export function trimNumber(
  number?: string | number | Decimal,
  precision = 8,
  exact?: boolean,
): string {
  if (!number) return "0";

  if (typeof number === "object") number = number.toString();

  const data = typeof number === "number" ? number.toString() : number;
  const stringified = parseExponent(data);

  const pointIndex = stringified.indexOf(".");
  let result = "";

  if (pointIndex === -1) {
    result = data;

    if (exact === true) result += `.${"0".repeat(precision)}`;
  } else {
    if (typeof stringified !== "string") return stringified;

    const [firstPart, secondPart] = stringified.split(".");

    result = firstPart;

    if (!secondPart) return result;

    let calculatedLength = precision;

    for (let i = precision - 1; i >= 0; i--) {
      const digit = secondPart[i];

      if (digit === "0" && !exact) calculatedLength--;
      else break;
    }

    if (calculatedLength === 0) {
      if (exact === true) result += `.${"0".repeat(precision)}`;

      return result;
    }

    result += `.${secondPart.slice(0, calculatedLength)}`;

    if (exact === true && secondPart.length < precision)
      result += "0".repeat(precision - secondPart.length);
  }

  return result;
}

export function parseExponent(value: string) {
  const data = value.split(/[Ee]/);

  if (data.length === 1) return data[0];

  let z = "";
  let sign = "";
  let mag = Number(data[1]);

  let str = data[0].replace(".", "");

  if (value.startsWith("-")) {
    sign = "-";
    str = str.slice(1);
  }

  if (mag < 0) {
    mag++;
    z = `${sign}0.`;

    while (mag++) z += "0";

    return z + str;
  }

  if (mag < str.length) {
    const startIndex = str.match(/^0+(?!$)/)?.[0].length ?? 0;
    return `${sign}${str.slice(startIndex, mag)}.${str.slice(mag - str.length)}`;
  }

  mag -= str.length;

  while (mag--) z += "0";

  return sign + str + z;
}

export function formatNumberClosure() {
  const formatter = Intl.NumberFormat("en-US", {
    maximumFractionDigits: 20,
  });

  const exactFormatter = Intl.NumberFormat("en-US", {
    maximumFractionDigits: 20,
    minimumFractionDigits: 20,
  });

  const compactFormatter = Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 20,
  });

  return function (
    number?: number | string | Decimal | null,
    precision = 8,
    exact = false,
    compact = false,
  ) {
    let formattedNumber = number;

    if (number === undefined || number === null) formattedNumber = 0;

    if (typeof number === "object" && number !== null)
      formattedNumber = number.toString();

    if (exact) {
      formattedNumber = exactFormatter.format(formattedNumber as number);
    } else if (compact) {
      formattedNumber = compactFormatter.format(formattedNumber as number);

      if (precision !== undefined) {
        const [value, suffix] = formattedNumber.split(/(?=[A-Za-z])/);

        let [firstPart, secondPart] = value.split(".");

        if (secondPart === undefined || precision === 0) {
          if (suffix !== undefined) firstPart += suffix;

          return firstPart;
        }

        secondPart = secondPart.slice(0, precision);

        if (+secondPart === 0 && exact === false) {
          if (suffix !== undefined) firstPart += suffix;

          return firstPart;
        }

        formattedNumber = `${firstPart}.${secondPart}`;

        if (suffix !== undefined) formattedNumber += suffix;
      }

      return formattedNumber;
    } else {
      const isArrow = /[<>]/g.test(formattedNumber!.toString());

      if (formattedNumber && isArrow) {
        const [_, value] = formattedNumber.toString().split("<" || ">");
        formatter.format(Number(value));
      } else {
        formattedNumber = formatter.format(formattedNumber as number);
      }
    }

    if (precision !== undefined) {
      // eslint-disable-next-line prefer-const
      let [firstPart, secondPart] = formattedNumber.toString().split(".");

      if (secondPart === undefined || precision === 0) return firstPart;

      secondPart = secondPart.slice(0, precision);

      if (+secondPart === 0 && exact === false) return firstPart;

      formattedNumber = `${firstPart}.${secondPart}`;
    }

    return formattedNumber as string;
  };
}

export const formatNumber = formatNumberClosure();

export const safeDecimal = (value?: Numeric) => {
  if (value === undefined || value === null || Number.isNaN(value)) {
    return new Decimal(0);
  }

  try {
    return new Decimal(value);
  } catch {
    return new Decimal(0);
  }
};

export const formatBalance = (value: Numeric, precision = 8) => {
  const decimal = safeDecimal(value);

  if (decimal.isZero()) return "0.00";

  return trimNumber(decimal.toString(), precision);
};

import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { z } from "zod";

import { useCurrencies } from "@/api/queries/currencies";
import { CurrencyItem } from "@/components/home/currency-item";

const searchSchema = z.object({
  backUrl: z.string().optional(),
});

export const Route = createFileRoute("/withdraw/currency/")({
  component: WithdrawCurrency,
  validateSearch: searchSchema,
});

function WithdrawCurrency() {
  const { currenciesArray } = useCurrencies();

  const { backUrl } = Route.useSearch();

  const navigate = useNavigate();

  return (
    <>
      <BackButton
        onClick={() =>
          navigate({
            to: backUrl || "/",
          })
        }
      />
      <div className="px-4 py-10">
        <img
          src="/whitechain-logo.svg"
          alt="Whitechain Logo"
          className="mx-auto mb-4 block dark:hidden"
        />
        <img
          src="/whitechain-logo-dark.svg"
          alt="Whitechain Logo Dark"
          className="mx-auto mb-4 hidden dark:block"
        />
        <div className="mx-auto max-w-[340px] rounded-4xl bg-wrapper p-6">
          <div className="mb-4 text-center text-lg font-medium text-foreground">
            Select asset
          </div>
          <div>
            {currenciesArray.map((currency) => (
              <CurrencyItem
                currency={currency}
                key={currency.id}
                onClick={() =>
                  navigate({
                    to: "/withdraw/currency/$currency/",
                    params: { currency: currency.ticker },
                  })
                }
                className="cursor-pointer border-border px-0 first:pt-0 last:pb-0"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import {
  createFileRoute,
  useNavigate,
  useParams,
  useSearch,
} from "@tanstack/react-router";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { isAddress } from "viem";
import { z } from "zod";

import { useBalances } from "@/api/queries/balances";
import { Button } from "@/components/ui/button";
import { cn } from "@/utils/cn";
import { onChangeCovertDecimal, onKeyDownFloat } from "@/utils/input";

const searchSchema = z.object({
  address: z.string().refine(isAddress, "Invalid address"),
  amount: z.string().optional(),
});

const paramsSchema = z.object({
  currency: z.string(),
});

export const Route = createFileRoute(
  "/withdraw/currency/$currency/_layout/amount",
)({
  component: WithdrawCurrencyAmountPage,
  validateSearch: searchSchema,
  parseParams: paramsSchema.parse,
});

const formSchema = z.object({
  amount: z
    .string()
    .min(1, "Amount is required")
    .refine((v) => +v > 0, "Amount must be greater than 0"),
});

function WithdrawCurrencyAmountPage() {
  const navigate = useNavigate();

  const params = useParams({
    from: "/withdraw/currency/$currency/_layout/amount",
  });

  const search = useSearch({
    from: "/withdraw/currency/$currency/_layout/amount",
  });

  const { balances } = useBalances({ format: false });

  const primaryBalance = useMemo(
    () => balances[params.currency as Ticker],
    [balances, params],
  );

  const { formState, setValue, handleSubmit, register } = useForm({
    defaultValues: { amount: search.amount ?? "" },
    mode: "onChange",
    resolver: zodResolver(formSchema),
  });

  const onSubmit = handleSubmit((data) => {
    if (+data.amount > +primaryBalance) {
      toast.warning("Insufficient balance", {
        action: {
          label: "Top up",
          onClick: () => navigate({ to: "/deposit" }),
        },
      });
    } else {
      navigate({
        to: "/withdraw/currency/$currency/submit",
        search: { address: search.address, amount: data.amount },
        params,
      });
    }
  });

  return (
    <>
      <BackButton onClick={() => navigate({ to: "/withdraw/currency/" })} />
      <form onSubmit={onSubmit}>
        <h1 className="mb-2 text-center text-lg font-medium text-foreground">
          Withdraw {params.currency}
        </h1>
        <p className="mb-3 break-all text-center text-text">{search.address}</p>
        <div className="mb-1 flex items-center justify-between">
          <label htmlFor="amount" className="text-text">
            Amount
          </label>
          <button
            className="font-medium text-primary dark:text-secondary"
            type="button"
            onClick={() => setValue("amount", primaryBalance)}
          >
            {primaryBalance} {params.currency}
          </button>
        </div>
        <div className="mb-4">
          <div
            className={cn(
              "flex h-12 items-center rounded-lg border border-border pr-2 transition-colors focus-within:border-primary",
            )}
          >
            <input
              id="amount"
              inputMode="decimal"
              data-precision={params.currency === "USDC" ? 6 : 8}
              placeholder="0"
              className="h-full grow bg-transparent pl-4 pr-2 text-foreground outline-none"
              onKeyDown={onKeyDownFloat}
              onPaste={(e) => e.preventDefault()}
              {...register("amount", {
                onChange: onChangeCovertDecimal,
              })}
            />
            <div className="whitespace-nowrap font-medium text-foreground">
              {params.currency}
            </div>
          </div>
          {formState.errors.amount && (
            <div className="pt-1 text-sm text-red-500">
              {formState.errors.amount.message}
            </div>
          )}
        </div>
        <Button loading={formState.isSubmitting} className="w-full">
          Continue
        </Button>
      </form>
    </>
  );
}
